body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-container {
  min-height: 100vh;
}

main {
  flex: 1;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-primary,
.btn-outline-primary {
  background-color: rgb(170, 39, 6);
  color: white;
}

.btn-flat {
  background-color: rgb(170, 39, 6);
  color: white;
}

.rating span {
  color: #eb7226;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}
.product img {
  width: 100%;
  max-width: 400px;
}
.product-info {
  padding: 1rem;
}

.img-large {
  max-width: 100%;
}

.img-thumbnail {
  height: 80px;
}

.small-container {
  max-width: 600px;
  border-radius: 100;
}

.bg {
  background-image: url('https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).webp');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}

.text-bold {
  font-weight: bold;
}

/*sidebar*/
.navbar-brand {
  font-weight: bold;
}
.side-navbar {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: black;
  transition: 0.5s;
}
.site-container {
  transition: 0.4s;
}
.active-cont {
  margin-left: 240px;
}

.active-nav {
  left: 0;
}
